.multi-date-tags-wrap {
  display: flex;
  flex-wrap: wrap;
}

.multi-date-tags-wrap .multi-date-tags-item {
  margin-top: 8px;
}

.form-wrapper-card > .ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-wrapper-card label {
  white-space: nowrap;
}

.form-wrapper-card .check-group > div {
  flex-wrap: wrap;
}

.form-wrapper-card .ant-space,
.form-wrapper-card .ant-input-number,
.form-wrapper-card .ant-picker {
  width: 100% !important;
}

.form-field-wrapper {
  width: 100%;
  display: grid;
  gap: 0px;
  grid-auto-rows: minmax(auto, auto);
  align-items: baseline;
}

.form-field-wrapper .ant-input-number {
  width: 100% !important;
}

.form-field-wrapper .ant-form-item {
  margin-bottom: 8px;
  margin-right: 8px;
}

.form-delete-grib-button {
  margin-bottom: 14px;
  align-self: end;
}

.form-wrapper-card .ant-typography {
  margin-top: 8px;
}
.form-wrapper-card .ant-btn-dashed {
  margin-bottom: 8px;
}

.nb-columns-1 {
  grid-template-columns: repeat(1, 1fr);
}
.nb-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}
.nb-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}
.nb-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}
.nb-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}
.nb-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}
.nb-columns-8 {
  grid-template-columns: repeat(8, 1fr);
}
.nb-columns-10 {
  grid-template-columns: repeat(10, 1fr);
}
.nb-columns-11 {
  grid-template-columns: repeat(11, 1fr);
}
.nb-columns-12 {
  grid-template-columns: repeat(12, 1fr);
}

.col-1 {
  grid-column: 1;
}

.col-2 {
  grid-column: 2;
}

.col-3 {
  grid-column: 3;
}

.col-4 {
  grid-column: 4;
}

.col-5 {
  grid-column: 5;
}

.col-6 {
  grid-column: 6;
}

.col-7 {
  grid-column: 7;
}

.col-8 {
  grid-column: 8;
}

.col-9 {
  grid-column: 9;
}

.col-10 {
  grid-column: 10;
}

.col-11 {
  grid-column: 11;
}

.col-12 {
  grid-column: 12;
}

.col-1.size-2 {
  grid-column: 1 / 3;
}

.col-1.size-2 {
  grid-column: 1 / 3;
}

.col-1.size-3 {
  grid-column: 1 / 4;
}

.col-1.size-4 {
  grid-column: 1 / 5;
}

.col-1.size-5 {
  grid-column: 1 / 6;
}

.col-2.size-2 {
  grid-column: 2 / 4;
}

.col-2.size-3 {
  grid-column: 2 / 5;
}

.col-2.size-4 {
  grid-column: 2 / 6;
}

.col-3.size-2 {
  grid-column: 3 / 5;
}

.col-4.size-2 {
  grid-column: 4 / 6;
}

.col-4.size-3 {
  grid-column: 4 / 7;
}

.col-5.size-2 {
  grid-column: 5 / 7;
}

.col-6.size-5 {
  grid-column: 6 / 11;
}

.col-7.size-2 {
  grid-column: 7 / 9;
}

.col-9.size-2 {
  grid-column: 9 / 11;
}

.row-1 {
  grid-row: 1;
}

.row-2 {
  grid-row: 2;
}

.row-3 {
  grid-row: 3;
}

.row-4 {
  grid-row: 4;
}

.row-5 {
  grid-row: 5;
}

.row-6 {
  grid-row: 6;
}

.row-7 {
  grid-row: 7;
}

.row-8 {
  grid-row: 8;
}

.row-9 {
  grid-row: 9;
}

.row-10 {
  grid-row: 10;
}

.row-11 {
  grid-row: 11;
}

.row-12 {
  grid-row: 12;
}

.row-13 {
  grid-row: 13;
}

.row-14 {
  grid-row: 14;
}

.row-15 {
  grid-row: 15;
}

.row-16 {
  grid-row: 16;
}

.row-15 {
  grid-row: 17;
}

.row-16 {
  grid-row: 18;
}
